import { getPrint } from '@/api/request'
import { openBase64NewTab } from '@/utils/print/base64ToPdf'
import { setAlertError } from '@/utils/store/alert'

export async function openPrint (type, params = {}) {
  try {
    const response = await getPrint(type, params)
    openBase64NewTab(response.data.base64)
  } catch (e) {
    await setAlertError(e, 'Не удалось загрузить документы для печати')
  }
}
